import React, { useState, Fragment } from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * Context API
 */
import { useGlobalState } from '@store/hooks';

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Assest
 */
import '../components/navigation/assets/styles/_index.scss';
import CloseIcon from '@static/svg/close_icon.svg';

const Sitemap = ({active, navigationToggle}) => {
  const [expanded, setExpanded] = useState(false);
  const { services: modalServices } = useGlobalState('modals');
  const handleClick = () => {
    navigationToggle();
    setExpanded(false);
  };

  const handleExpand = (event) => setExpanded(!event.target.parentNode.classList.contains('show'));
  const handleClose = () => expanded ? setExpanded(false) : navigationToggle();
  const modalOpen = (event) => {
    event.preventDefault();
    modalServices.modalToggle({name: 'property_search', active: true});
  }

  const data = useStaticQuery(graphql`
    query {
      strapiMenuBurger {
        Menu {
          Parent_Label
          Parent_URL
          Sub_Menus {
            Label
            URL
          }
        }
      }
    }
  `);

  return (


      <Layout>
      <Helmet 
        bodyAttributes={{
          class: 'static pages-sitemap-js'
        }}
      />
        <div className="page-body">


      <section className="sitemap post-content">
        <Container>
          <h1>Sitemap</h1>
          <Row>
            <Col lg="10" >
              <ul className="sitemap-main-list">
                {data.strapiMenuBurger.Menu.map((Menu, key) => {
                  return <>
                    {Menu.Sub_Menus == "" && Menu.Parent_URL != "#search" &&
                      <li className="list-single"><Link to={`/${Menu.Parent_URL}`} className="nav-link">{Menu.Parent_Label}</Link></li>
                    }
                    {Menu.Sub_Menus == "" && Menu.Parent_URL == "#search" &&
                      <li className="list-single"><Nav.Link href="/" onClick={modalOpen} className="nav-link">{Menu.Parent_Label}</Nav.Link></li>
                    }
                    {Menu.Sub_Menus != "" &&
                    <li className="list-single-child">
                    {Menu.Parent_Label}
                      <ul className="sub-menu sitemap-sub-menu-list">

                          {Menu.Sub_Menus.map((Sub_Menus, index) => {
                            return (
                              <>

                          <li>
                            <Link to={`/${Sub_Menus.URL}`}>{Sub_Menus.Label}</Link>
                          </li>
                              </>
                            )
                          })}
                        </ul>
                    </li>
                    }
                  </>
                })}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      </div>


    </Layout>


  )
}

export default Sitemap;
